import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-5 mb-8 text-gray-800 text-center font-light">
    <p className="mt-5">
      4640 Cass St #99153
      <br /> San Diego – CA 92169
      <br /> United States
    </p>
    <p className="mt-10">
      Copyright© FLEXA® - Fast Acting Advanced Natural Pain Relief. All rights reserved.
    </p>
  </footer>
);

export default Footer;
