import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <img src={`../../../flexa-logo-sm-2.png`} alt="" id="logo" />
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4 text-xs md:text-base" href="#info">
          Info
        </AnchorLink>
        <AnchorLink className="px-4 text-xs md:text-base" href="#ingredients">
          Ingredients
        </AnchorLink>
        <AnchorLink className="px-4 text-xs md:text-base" href="#direction">
          Direction for use
        </AnchorLink>
        <AnchorLink className="px-4 text-xs md:text-base" href="#contact">
          Contact
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
